import { configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer as createSentryEnhancer } from '@sentry/react';
import { persistStore } from 'redux-persist';
import { responsiveStoreEnhancer } from 'redux-responsive';

import chatServiceApi from '@@api/chatService/rtk';
import api from '@@api/rtk';
import webhookServiceApi from '@@api/webhookService/rtk';
import customMiddlewares from '@@redux/middlewares';

import { rootReducer } from './index';

export const initStore = (preloadedState = {}) => {
  const isTest = process.env.NODE_ENV === 'test';

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(api.middleware)
        .concat(chatServiceApi.middleware)
        .concat(webhookServiceApi.middleware)
        .concat(customMiddlewares);
    },
    preloadedState,
    devTools: window.__DEV__,
    enhancers: (getDefaultEnhancers) => {
      return getDefaultEnhancers()
        .concat(responsiveStoreEnhancer)
        .concat(createSentryEnhancer());
    },
  });

  if (!isTest) {
    const persistor = persistStore(store);

    return { store, persistor };
  }

  return { store };
};
