import { createApi } from '@reduxjs/toolkit/query/react';
import { RawAxiosRequestHeaders } from 'axios';

import { appInstance } from '@@services/transport/axios';

import type { AxiosBaseQuery, BaseQueryError } from '@@types/api';

const axiosBaseQuery = (): AxiosBaseQuery => async (axiosConfig) => {
  try {
    const { ID } = require('interface/Branch');
    const { LANDING, NICHE_CATEGORY } = require('app/Env/settings');

    const extraHeaders: RawAxiosRequestHeaders = {};

    if (LANDING) {
      extraHeaders['X-Company-Landing'] = LANDING;
    }

    if (NICHE_CATEGORY) {
      extraHeaders['X-Company-Niche-category'] = NICHE_CATEGORY;
    }

    // Default value is true
    if (axiosConfig.extraConfig?.withBranch !== false) {
      extraHeaders['X-Branch-Id'] = ID;
    }

    // eslint-disable-next-line no-param-reassign
    axiosConfig.extraHeaders = extraHeaders;

    const { data, ...meta } = await appInstance(axiosConfig);

    return { data, meta };
  } catch (axiosError) {
    return {
      error: axiosError as BaseQueryError,
    };
  }
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Order',
    'Book',
    'NovapostAccounts',
    'Taxes',
    'TaxGroups',
    'OrderSaleTaxRules',
    'PostingTaxRules',
    'OrdersSettings',
    'PublicPageSettings',
    'FlatCategory',
    'ClientsSuggest',
    'SuppliersSuggest',
    'GetClient',
    'OrderFilters',
    'EventLog',
  ],
  endpoints: () => ({}),
});

export default api;
