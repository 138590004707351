import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { createMigrate, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  cachedTables,
  fullCachedTables,
  nestedTables,
  sortAndFieldsCachedFletcherTables,
  sortAndFieldsCachedTables,
} from './constants';

const tablesTransform = createTransform(
  (inboundState) => {
    const tableNames = {};

    cachedTables.forEach((tableName) => {
      tableNames[tableName] = omit(
        inboundState[tableName],
        'data',
        'loading',
        'error',
      );
    });

    fullCachedTables.forEach((tableName) => {
      tableNames[tableName] = omit(inboundState[tableName], 'loading', 'error');
    });

    sortAndFieldsCachedTables.forEach((tableName) => {
      const fletcherTableName = sortAndFieldsCachedFletcherTables.find(
        (name) => tableName === name,
      );

      const actualTableName = fletcherTableName
        ? Object.keys(inboundState).find((key) => {
            return !key.indexOf(fletcherTableName);
          })
        : tableName;

      tableNames[tableName] = pick(inboundState[actualTableName], [
        'sort',
        'fields',
      ]);
    });

    nestedTables.forEach((tableName) => {
      tableNames[tableName] = pick(inboundState[tableName], ['persistConfig']);
    });

    return tableNames;
  },
  (outboundState) => outboundState,
  { whitelist: ['tables'] },
);

const scheduleTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState) => outboundState,
  { whitelist: ['schedule'] },
);

const uiTransform = createTransform(
  (inboundState) => ({
    sidebar: pick(inboundState.sidebar, ['isCollapsed']),
  }),
  (outboundState) => outboundState,
  { whitelist: ['ui'] },
);

const migrations = {
  1: (state) => {
    // don`t delete
    if (state.schedule && !state.schedule.settings) {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          settings: {
            stepInTime: 60,
            weekSlotRowsCount: 2,
            orderFields: ['client_field', 'order_id_label', 'asset_field'],
          },
        },
      };
    }

    return state;
  },
};

export const persistConfig = {
  key: 'app',
  version: 1,
  storage,
  migrate: createMigrate(migrations, { debug: false }),
  whitelist: ['tables', 'schedule', 'ui'],
  transforms: [tablesTransform, scheduleTransform, uiTransform],
};
