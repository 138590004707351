import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchRequest } from '@@services/transport/fetch';

export const fetchDndTreeData = createAsyncThunk(
  'dndTree/fetchDndTreeData',
  async ({ requestUrl, requestPayload, sccb }, { rejectWithValue }) => {
    const response = await fetchRequest({
      url: requestUrl,
      payload: requestPayload,
      sccb,
    });

    if (response.message) {
      return rejectWithValue(response.message);
    }

    return response;
  },
);

const dndTreeSlice = createSlice({
  name: 'dndTree',
  initialState: {},
  reducers: {
    updateDndTreeData: (state, { payload }) => {
      const { name, data } = payload;

      state[name] = {
        ...state[name],
        data,
        loading: false,
        error: false,
      };
    },
    setSelectedTreeItem: (state, { payload }) => {
      const { name, id } = payload;

      state[name] = {
        ...state[name],
        selected: id,
      };
    },
    setTotalCustomCellsWidth: (state, { payload }) => {
      const { name, width } = payload;

      state[name] = {
        ...state[name],
        tableWidth: width,
      };
    },
    externalReloadTree(state, { payload }) {
      const { name, shouldUpdateTree } = payload;

      state[name] = {
        ...state[name],
        shouldUpdateTree,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDndTreeData.pending, (state, { meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...state[name],
        loading: true,
        error: false,
      };
    });

    builder.addCase(fetchDndTreeData.fulfilled, (state, { payload, meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...state[name],
        ...payload,
        loading: false,
        error: false,
      };
    });

    builder.addCase(fetchDndTreeData.rejected, (state, { meta }) => {
      const { name } = meta.arg;

      state[name] = {
        ...state[name],
        data: [],
        total: 0,
        loading: false,
        error: true,
      };
    });
  },
});

export const {
  updateDndTreeData,
  setSelectedTreeItem,
  setTotalCustomCellsWidth,
  externalReloadTree,
} = dndTreeSlice.actions;

export default dndTreeSlice.reducer;
