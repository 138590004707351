import { syncHistoryWithStore } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import { persistor, store } from '@@redux/store';

export default {
  store,
  persistor,
  // syncHistoryWithStore must be removed with caution, as the behavior of history.listen changes
  history: syncHistoryWithStore(createBrowserHistory(), store),
};
