import _isNil from 'lodash/isNil';

import { fetchRequest } from '@@services/transport/fetch';

export const getClientById = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/get-client',
    payload,
    ...options,
  });
};

export const getEnvironment = (options = {}) => {
  return fetchRequest({
    url: '/settings/env',
    ...options,
  });
};

export const generateDiscountCode = (payload, options) => {
  return fetchRequest({
    url: '/settings/generate-discount-code',
    method: 'POST',
    payload,
    ...options,
  });
};

export const getClientTags = (payload, options) => {
  return fetchRequest({
    url: '/settings/get-client-tags',
    payload,
    ...options,
  });
};

export const getCurrencies = (_, options = {}) => {
  return fetchRequest({
    url: '/settings/get-currencies',
    ...options,
  });
};

export const updateInvoicesSettings = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/update-invoices-settings',
    payload,
    method: 'POST',
    ...options,
  });
};

export const updateAccounting = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/update-accounting-settings',
    payload,
    method: 'POST',
    ...options,
  });
};

export const updateCompanyRequisitesSettings = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/update-company-requisites-settings',
    payload,
    method: 'POST',
    ...options,
  });
};

export const updateCompanyCommonSettings = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/update-company-common-settings',
    payload,
    method: 'POST',
    ...options,
  });
};

export const companyLogoUpload = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/company-logo/upload',
    payload,
    method: 'POST',
    ...options,
  });
};

export const companyLogoDelete = (_, options = {}) => {
  return fetchRequest({
    url: '/settings/company-logo/delete',
    method: 'POST',
    ...options,
  });
};

export const createNotificationTemplate = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/json/create-notification-template',
    method: 'POST',
    payload,
    ...options,
  });
};

export const updateNotificationTemplate = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/json/update-notification-template',
    method: 'POST',
    payload,
    ...options,
  });
};

export const deleteNotificationTemplate = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/json/delete-notification-template',
    method: 'POST',
    payload,
    ...options,
  });
};

export const getResources = (opts = {}) => {
  const { payload: { branchId } = {}, ...rest } = opts;

  return fetchRequest({
    url: '/settings/get-resources-in-order',
    payload: {
      branch: _isNil(branchId) ? require('interface/Branch').ID : branchId,
    },
    ...rest,
  });
};

export const getEmployeesWithPermissionsSet = (payload, options = {}) => {
  return fetchRequest({
    url: '/settings/get-employees-with-permissions-set',
    method: 'POST',
    payload,
    ...options,
  });
};

export const createCallSettingTask = (payload, options = {}) => {
  return fetchRequest({
    url: `/settings/call-setting-task`,
    method: 'POST',
    payload,
    ...options,
  });
};

export const updateCallSettingTask = (payload, options = {}) => {
  const { company, ...rest } = payload;

  return fetchRequest({
    url: `/settings/call-setting-task/${company}`,
    method: 'PUT',
    payload: rest,
    ...options,
  });
};

export const deleteCallSettingTask = (payload, options = {}) => {
  const { company } = payload;

  return fetchRequest({
    url: `/settings/call-setting-task/${company}`,
    method: 'DELETE',
    ...options,
  });
};

export const getCallSettingLead = (payload, options = {}) => {
  const { company } = payload;

  return fetchRequest({
    url: `/settings/call-setting-lead/${company}`,
    method: 'GET',
    ...options,
  });
};

export const updateCallSettingLead = (payload, options = {}) => {
  const { company, ...rest } = payload;

  return fetchRequest({
    url: `/settings/call-setting-lead/${company}`,
    method: 'PUT',
    payload: rest,
    ...options,
  });
};

export const updateSaleSetting = (payload, options = {}) => {
  const { company, ...rest } = payload;

  return fetchRequest({
    url: `/settings/sale/${company}`,
    method: 'PUT',
    payload: rest,
    ...options,
  });
};

export const updateClientSetting = (payload, options = {}) => {
  const { company, ...rest } = payload;

  return fetchRequest({
    url: `/settings/client/${company}`,
    method: 'PUT',
    payload: rest,
    ...options,
  });
};
