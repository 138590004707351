export const PAYMENT_TYPES = {
  PAYMENT: 0,
  TRANSFER: 1,
  CORRECTION: 2,
};

export const TRANSACTION_TYPE = {
  INCOME: 'income',
  OUTCOME: 'outcome',
  TRANSFER: 'transfer',
  CORRECTION: 'correction',
};

export const TRANSACTION_MODULE_NAME = {
  ORDER: 'order',
  SALE: 'sale',
  PAYMENT: 'payment',
  REFUND: 'refund',
  POSTING: 'posting',
  CLIENT: 'client',
};

export const PAYMENT_METHOD_TYPES = {
  CASH: 0,
  CASHLESS: 1,
  IN_DEBT: -1,
} as const;

export const CASHFLOW_CATEGORY_TYPE = {
  UNDEFINED: 0,
  CUSTOM: 1,
  PAYMENT_FOR_GOODS: 2,
  PAYMENT_TO_SUPPLIERS: 3,
  PAYMENT_TO_CLIENT: 4,
  PAYMENT_FROM_SUPPLIERS: 5,
  ORDER_PREPAYMENT: 6,
  ORDER_RETURN: 7,
};

export const FISCAL_RECEIPT_TYPE = {
  CASH: 'CASH',
  CASHLESS: 'CASHLESS',
  CARD: 'CARD',
};

export const PAYMENT_DIRECTION = {
  INCOME: 0,
  OUTCOME: 1,
} as const;
